// ========== imports ========== //

// ========== Page ========== //
function QuestionMcq03({
  question,
  questionText,
  register,
  qErrors,
  reversed,
}) {
  // ========== Hook consts ========== //

  return (
    /* ASSESSOR BLOCK - OUTSIDE Q CONTAINER */
    <div className="cast-form__question-container">
      <div className="cast-form__question cast-form__question--radio">
        <p className="p--m cast-form__question__qtext">
          {<b>{question}</b>}: {questionText}
        </p>
        {reversed === true ? (
          <div className="radio-options">
            <div>
              <label htmlFor={question + "_3"} className="p--sm">
                0
              </label>
              <input
                type="radio"
                id={question + "_3"}
                name={question}
                value={3}
                {...register(question, {})}
              />
            </div>
            <div>
              <label htmlFor={question + "_2"} className="p--sm">
                1
              </label>
              <input
                type="radio"
                id={question + "_2"}
                name={question}
                value={2}
                {...register(question, {})}
              />
            </div>
            <div>
              <label htmlFor={question + "_1"} className="p--sm">
                2
              </label>
              <input
                type="radio"
                id={question + "_1"}
                name={question}
                value={1}
                {...register(question, {})}
              />
            </div>
            <div>
              <label htmlFor={question + "_0"} className="p--sm">
                3
              </label>
              <input
                type="radio"
                id={question + "_0"}
                name={question}
                value={0}
                {...register(question, {})}
              />
            </div>
          </div>
        ) : (
          <div className="radio-options">
            <div>
              <label htmlFor={question + "_0"} className="p--sm">
                0
              </label>
              <input
                type="radio"
                id={question + "_0"}
                name={question}
                value={0}
                {...register(question, {})}
              />
            </div>
            <div>
              <label htmlFor={question + "_1"} className="p--sm">
                1
              </label>
              <input
                type="radio"
                id={question + "_1"}
                name={question}
                value={1}
                {...register(question, {})}
              />
            </div>
            <div>
              <label htmlFor={question + "_2"} className="p--sm">
                2
              </label>
              <input
                type="radio"
                id={question + "_2"}
                name={question}
                value={2}
                {...register(question, {})}
              />
            </div>
            <div>
              <label htmlFor={question + "_3"} className="p--sm">
                3
              </label>
              <input
                type="radio"
                id={question + "_3"}
                name={question}
                value={3}
                {...register(question, {})}
              />
            </div>
          </div>
        )}
        {/* ERROR BLOCK - WITHIN Q CONTAINER */}
        <div className="cast-form__error-message">
          {qErrors && (
            <p className="input__error-message">{qErrors?.message}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuestionMcq03;
