import Joi from "joi";

const schemaFormSectionE = Joi.object({
  E1: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  E1_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  E1_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  E2: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  E2_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  E2_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  E3: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  E3_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  E3_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  E4: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  E4_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  E4_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  E5: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  E5_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  E5_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  E6: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  E6_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  E6_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  E7: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  E7_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  E7_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  E8: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  E8_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  E8_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  E9: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  E9_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  E9_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  E10: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  E10_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  E10_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  E11: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  E11_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  E11_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  E12: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  E12_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  E12_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  E13: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  E13_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  E13_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  E14: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  E14_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  E14_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  E15: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  E15_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  E15_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  E16: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  E16_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  E16_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
});

export default schemaFormSectionE;
