// ========== imports ========== //

import React, { useRef, useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse, getFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import { schemaFormSection1 } from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionMcqYesNoMaybe,
  QuestionMcqYesNo,
  QuestionMcqList,
  QuestionMcqListHorizontal,
  QuestionShortText,
  QuestionTextBox,
} from "./FormComponents";

// ========== Page ========== //

function Section1({ environment }) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(false);

  const [existingValues, setExistingValues] = useState({});

  const form = useRef();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  const {
    register: registerSection1,
    handleSubmit: handleSubmitSection1,
    reset,
    formState: { errors },
    getValues: getValuesSection1,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSection1),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE
  useEffect(() => {
    getFormResponseData();
  }, []);
  useEffect(() => {
    if (existingValues) {
      for (const [key, value] of Object.entries(existingValues)) {
        if (key.slice(0, 7) === "details" && value) {
          setValue(key, value, {});
        }
      }
    }
  }, [existingValues]);

  const params = useParams();
  const navigate = useNavigate();
  let route = params.route;

  const getFormResponseData = () => {
    console.log(route);
    try {
      getFormResponse(
        sessionStorage.token,
        route,
        (error, formDataRetrieved) => {
          if (error) {
            if (error instanceof ServerError) {
              navigate("/admin/castresponsestable");
              toast.error(error.message);
              logger.error(error.message);
            } else {
              navigate("/admin/castresponsestable");
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }
          setExistingValues(formDataRetrieved);
        }
      );
    } catch (error) {
      navigate("/admin/castresponsestable");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  const saveFormData = async () => {
    const values = getValuesSection1();

    console.log(values);

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage.token,
        route,
        values,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          toast.success("Your answers have been saved");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section A");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <form
      ref={form}
      onSubmit={handleSubmitSection1((data) => saveFormData())}
      className="cast-form"
      noValidate={true}
    >
      <div className="cast-form__title-block">
        <h2 className="h2">Section 1</h2>
        <p className="p--m">
          Please complete the details below and save your progress once you have
          finished and are ready to move to the next section.
          <br />
          &nbsp;
        </p>
      </div>
      <QuestionShortText
        question={"detailsFullName"}
        questionText={"* Full name:"}
        register={registerSection1}
        qErrors={errors.detailsFullName}
        section={"1"}
      />
      <QuestionShortText
        question={"detailsCandidateEmail"}
        questionText={"* Email:"}
        register={registerSection1}
        qErrors={errors.detailsCandidateEmail}
        section={"1"}
      />
      <QuestionShortText
        question={"detailsOrganisation"}
        questionText={"* Company/Organisation:"}
        register={registerSection1}
        qErrors={errors.detailsOrganisation}
        section={"1"}
      />
      <QuestionShortText
        question={"detailsRole"}
        questionText={"* Position/Role:"}
        register={registerSection1}
        qErrors={errors.detailsRole}
        section={"1"}
      />
      <QuestionMcqListHorizontal
        question={"detailsGender"}
        questionText={"Please select your gender:"}
        register={registerSection1}
        qErrors={errors.detailsGender}
        labels={["male", "female", "non-binary", "other", "prefer not to say"]}
        section={"1"}
      />
      {environment === "edit" ? (
        <QuestionShortText
          question={"detailsAssessor"}
          questionText={"Name of Assessor:"}
          register={registerSection1}
          qErrors={errors.detailsAssessor}
          section={"1"}
        />
      ) : null}
      {environment === "edit" ? (
        <div className="cast-form__question-container">
          <div className="cast-form__question">
            <label className="label--select p--m" htmlFor="detailsDateAssessed">
              {`Date Assessed: (Current Date is ...`}
            </label>
            <input
              className="p--sm"
              name="detailsDateAssessed"
              id="detailsDateAssessed"
              type="date"
              {...registerSection1("detailsDateAssessed", {})}
            />
          </div>
          <div className="cast-form__error-message">
            {errors.detailsDateAssessed && (
              <p className="input__error-message">
                {errors.detailsDateAssessed?.message}
              </p>
            )}
          </div>
        </div>
      ) : null}

      {formDataBeingSent ? (
        <button type="button" className="cta cta--accent--clicked">
          Submit
        </button>
      ) : null}
      {!formDataBeingSent ? (
        <button type="submit" className="cta cta--accent">
          Submit
        </button>
      ) : null}
    </form>
  );
}

export default withContext(Section1);
