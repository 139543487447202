import Joi from "joi";

const schemaFormSectionB = Joi.object({
  B1a: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3", "4", "5", "6", "7")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    B1a_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base": "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
    B1a_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
  B1b: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B1b_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B1b_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),

  B1c: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B1c_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B1c_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B1d: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B1d_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B1d_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B1e: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B1e_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B1e_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B2: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B2_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B2_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B3: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B3_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B3_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B4a: Joi.string()
    .trim()
    .max(5)
    .valid(null, "", "Yes", "No", "Maybe")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    B4a_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base": "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
    }),
    B4a_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
  B4b: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B4b_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B4b_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B5: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B5_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B5_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B6: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B6_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B6_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B7: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B7_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B7_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B8: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B8_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B8_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B9a: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B9a_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B9a_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B9b: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B9b_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B9b_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B10: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B10_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B10_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B11: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B11_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B11_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B12a: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B12a_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B12a_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B12b: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B12b_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
  }),
  B12b_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
});

export default schemaFormSectionB;
