// ========== imports ========== //

import React, { useRef, useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse, getFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import { schemaFormSectionC } from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionMcq14Strongly,
} from "./FormComponents";

// ========== Page ========== //

function SectionC({ environment }) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(false);

  const [existingValues, setExistingValues] = useState({});

  const form = useRef();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  const {
    register: registerC,
    handleSubmit: handleSubmitC,
    reset,
    formState: { errors },
    getValues: getValuesC,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSectionC),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE
  useEffect(() => {
    getFormResponseData();
  }, []);
  useEffect(() => {
    if (existingValues) {
      for (const [key, value] of Object.entries(existingValues)) {
      /*   if (key[0] === "C") {
          setValue(key, value.response, {
            // TODO: investigate the benefit of these attributes
            // shouldValidate: true,
           // shouldDirty: true, 
          });
        } */
        if (key[0] === "C") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
        }
      }
    }
  }, [existingValues]);

  const params = useParams();
  const navigate = useNavigate();
  let route = params.route;

  const getFormResponseData = () => {
    try {
      getFormResponse(
        sessionStorage.token,
        route,
        (error, formDataRetrieved) => {
          if (error) {
            if (error instanceof ServerError) {
              navigate("/admin/castresponsestable");
              toast.error(error.message);
              logger.error(error.message);
            } else {
              navigate("/admin/castresponsestable");
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }
          setExistingValues(formDataRetrieved);
        }
      );
    } catch (error) {
      navigate("/admin/castresponsestable");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  const saveFormData = async () => {
    const values = getValuesC();

    console.log(values);

    const groupedValuesObject = {
      C1: {
        response: values.C1,
        score: values.C1_score,
        comment: values.C1_comment,
      },
      C2: {
        response: values.C2,
        score: values.C2_score,
        comment: values.C2_comment,
      },
      C3: {
        response: values.C3,
        score: values.C3_score,
        comment: values.C3_comment,
      },
      C4: {
        response: values.C4,
        score: values.C4_score,
        comment: values.C4_comment,
      },
      C5: {
        response: values.C5,
        score: values.C5_score,
        comment: values.C5_comment,
      },
      C6: {
        response: values.C6,
        score: values.C6_score,
        comment: values.C6_comment,
      },
      C7: {
        response: values.C7,
        score: values.C7_score,
        comment: values.C7_comment,
      },
      C8: {
        response: values.C8,
        score: values.C8_score,
        comment: values.C8_comment,
      },
      C9: {
        response: values.C9,
        score: values.C9_score,
        comment: values.C9_comment,
      },
      C10: {
        response: values.C10,
        score: values.C10_score,
        comment: values.C10_comment,
      },
      C11: {
        response: values.C11,
        score: values.C11_score,
        comment: values.C11_comment,
      },
      C12: {
        response: values.C12,
        score: values.C12_score,
        comment: values.C12_comment,
      },
    };

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage.token,
        route,
        groupedValuesObject,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          toast.success("Your answers have been saved");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section C");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <form
      ref={form}
      onSubmit={handleSubmitC((data) => saveFormData())}
      className="cast-form"
      noValidate={true}
    >
      <div className="cast-form__title-block">
        <h2 className="h2">Section C</h2>
        <p className="p--m">
          Score these statements from 0-3 based on how much you agree or
          disagree with them. <br />
          <br />
          0 strongly disagree <br />1 disagree, <br />2 agree <br />3 strongly
          agree
          <br />
          &nbsp;
        </p>
      </div>
      {/*  {errors
        ? Object.entries(errors).map((error) => {
            return <li key={error}>{error}</li>;
          })
        : null} */}
      <QuestionMcq14Strongly
        question={"C1"}
        questionText={
          "Consider this metaphor - A leopard will never change its spots."
        }
        register={registerC}
        qErrors={errors.C1}
        reversed={true}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"C1"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
        />
      ) : null}
      <QuestionMcq14Strongly
        question={"C2"}
        questionText={
          "Your thoughts can determine whether or not you can do something."
        }
        register={registerC}
        qErrors={errors.C2}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"C2"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
        />
      ) : null}
      <QuestionMcq14Strongly
        question={"C3"}
        questionText={
          "You can always substantially change how capable you are. "
        }
        register={registerC}
        qErrors={errors.C3}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"C3"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
        />
      ) : null}
      <QuestionMcq14Strongly
        question={"C4"}
        questionText={"People who change their minds are unreliable. "}
        register={registerC}
        qErrors={errors.C4}
        reversed={true}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"C4"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
        />
      ) : null}
      <QuestionMcq14Strongly
        question={"C5"}
        questionText={
          "I have overcome a difficult situation and conquered an important challenge."
        }
        register={registerC}
        qErrors={errors.C5}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"C5"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
        />
      ) : null}
      <QuestionMcq14Strongly
        question={"C6"}
        questionText={"I always finish whatever I begin. "}
        register={registerC}
        qErrors={errors.C6}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"C6"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
        />
      ) : null}
      <QuestionMcq14Strongly
        question={"C7"}
        questionText={
          '"Success is never final; failure is never fatal: It is the courage to continue that counts”. Ask for example from own life.'
        }
        register={registerC}
        qErrors={errors.C7}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"C7"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
        />
      ) : null}
      <QuestionMcq14Strongly
        question={"C8"}
        questionText={"It is better to praise talent than effort. "}
        register={registerC}
        qErrors={errors.C8}
        reversed={true}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"C8"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
        />
      ) : null}
      <QuestionMcq14Strongly
        question={"C9"}
        questionText={
          "A positive role model or mentor can always help someone change. "
        }
        register={registerC}
        qErrors={errors.C9}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"C9"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
        />
      ) : null}
      <QuestionMcq14Strongly
        question={"C10"}
        questionText={
          "Cognitive and emotional intelligence are something very basic about you that you can’t change very much."
        }
        register={registerC}
        qErrors={errors.C10}
        reversed={true}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"C10"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
        />
      ) : null}
      <QuestionMcq14Strongly
        question={"C11"}
        questionText={
          "If someone told you can’t do something, it probably means that you can’t."
        }
        register={registerC}
        qErrors={errors.C11}
        reversed={true}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"C11"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
        />
      ) : null}
      <QuestionMcq14Strongly
        question={"C12"}
        questionText={"Failure is always an opportunity"}
        register={registerC}
        qErrors={errors.C12}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"C12"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerC}
        />
      ) : null}

      {formDataBeingSent ? (
        <button type="button" className="cta cta--accent--clicked">
          Submit
        </button>
      ) : null}
      {!formDataBeingSent ? (
        <button type="submit" className="cta cta--accent">
          Submit
        </button>
      ) : null}
    </form>
  );
}

export default withContext(SectionC);
