// ========== imports ========== //

import React, { useRef, useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse, getFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import { schemaFormSectionB } from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionMcqYesNoMaybe,
  QuestionMcqYesNo,
  QuestionShortText,
  QuestionTextBox,
} from "./FormComponents";

// ========== Page ========== //

function SectionB({ environment }) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(false);

  const [existingValues, setExistingValues] = useState({});

  const form = useRef();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  const {
    register: registerB,
    handleSubmit: handleSubmitB,
    reset,
    formState: { errors },
    getValues: getValuesB,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSectionB),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE
  useEffect(() => {
    getFormResponseData();
  }, []);
  useEffect(() => {
    if (existingValues) {
      for (const [key, value] of Object.entries(existingValues)) {
       /*  if (key[0] === "B" && value) {
          setValue(key, value.response, {});
        } */
        if (key[0] === "B") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
        }
      }
    }
  }, [existingValues]);

  const params = useParams();
  const navigate = useNavigate();
  let route = params.route;

  const getFormResponseData = () => {
    console.log(route);
    try {
      getFormResponse(
        sessionStorage.token,
        route,
        (error, formDataRetrieved) => {
          if (error) {
            if (error instanceof ServerError) {
              navigate("/admin/castresponsestable");
              toast.error(error.message);
              logger.error(error.message);
            } else {
              navigate("/admin/castresponsestable");
              toast.warn(error.message);
              logger.warn(error.message);
              if (error.message === "session timed out, please log in again") {
                delete sessionStorage.token;
                window.location.reload(false);
              }
            }
            return;
          }
          setExistingValues(formDataRetrieved);
        }
      );
    } catch (error) {
      navigate("/admin/castresponsestable");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  const saveFormData = async () => {
    const values = getValuesB();

    console.log(values);

    const groupedValuesObject = {
      B1a: {
        response: values.B1a,
        score: values.B1a_score,
        comment: values.B1a_comment,
      },
      B1b: {
        response: values.B1b,
        score: values.B1b_score,
        comment: values.B1b_comment,
      },
      B1c: {
        response: values.B1c,
        score: values.B1c_score,
        comment: values.B1c_comment,
      },
      B1d: {
        response: values.B1d,
        score: values.B1d_score,
        comment: values.B1d_comment,
      },
      B1e: {
        response: values.B1e,
        score: values.B1e_score,
        comment: values.B1e_comment,
      },
      B2: {
        response: values.B2,
        score: values.B2_score,
        comment: values.B2_comment,
      },
      B3: {
        response: values.B3,
        score: values.B3_score,
        comment: values.B3_comment,
      },
      B4a: {
        response: values.B4a,
        score: values.B4a_score,
        comment: values.B4a_comment,
      },
      B4b: {
        response: values.B4b,
        score: values.B4b_score,
        comment: values.B4b_comment,
      },
      B5: {
        response: values.B5,
        score: values.B5_score,
        comment: values.B5_comment,
      },
      B6: {
        response: values.B6,
        score: values.B6_score,
        comment: values.B6_comment,
      },
      B7: {
        response: values.B7,
        score: values.B7_score,
        comment: values.B7_comment,
      },
      B8: {
        response: values.B8,
        score: values.B8_score,
        comment: values.B8_comment,
      },
      B9a: {
        response: values.B9a,
        score: values.B9a_score,
        comment: values.B9a_comment,
      },
      B9b: {
        response: values.B9b,
        score: values.B9b_score,
        comment: values.B9b_comment,
      },
      B10: {
        response: values.B10,
        score: values.B10_score,
        comment: values.B10_comment,
      },
      B11: {
        response: values.B11,
        score: values.B11_score,
        comment: values.B11_comment,
      },
      B12a: {
        response: values.B12a,
        score: values.B12a_score,
        comment: values.B12a_comment,
      },
      B12b: {
        response: values.B12b,
        score: values.B12b_score,
        comment: values.B12b_comment,
      },
    };

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage.token,
        route,
        groupedValuesObject,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          toast.success("Your answers have been saved");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section B");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <form
      ref={form}
      onSubmit={handleSubmitB((data) => saveFormData())}
      className="cast-form"
      noValidate={true}
    >
      <div className="cast-form__title-block">
        <h2 className="h2">Section B</h2>
        <p className="p--m">
          Through the next questions, you will be asked a bit about your life
          and the challenges you have faced.
          <br />
          &nbsp;
        </p>
      </div>
      <div className="cast-form__question-container">
        <div className="cast-form__question cast-form__question--radio">
          <p className="p--m cast-form__question__qtext">
            <b>B1 a:</b> How many different jobs have you had since you started
            full time work? (A change of job within the same organisation counts
            as one. Being self-employed also counts as one.)
          </p>
          <div className="radio-options">
            <p className="p--sm">number of jobs</p>
            <div>
              <label htmlFor="B1a_0" className="p--sm">
                0
              </label>
              <input
                type="radio"
                id="B1a_0"
                name="B1a"
                value={0}
                {...registerB("B1a", {})}
              />
            </div>
            <div>
              <label htmlFor="B1a_1" className="p--sm">
                1
              </label>
              <input
                type="radio"
                id="B1a_1"
                name="B1a"
                value={1}
                {...registerB("B1a", {})}
              />
            </div>
            <div>
              <label htmlFor="B1a_2" className="p--sm">
                2
              </label>
              <input
                type="radio"
                id="B1a_2"
                name="B1a"
                value={2}
                {...registerB("B1a", {})}
              />
            </div>
            <div>
              <label htmlFor="B1a_3" className="p--sm">
                3
              </label>
              <input
                type="radio"
                id="B1a_3"
                name="B1a"
                value={3}
                {...registerB("B1a", {})}
              />
            </div>
            <div>
              <label htmlFor="B1a_4" className="p--sm">
                4
              </label>
              <input
                type="radio"
                id="B1a_4"
                name="B1a"
                value={4}
                {...registerB("B1a", {})}
              />
            </div>
            <div>
              <label htmlFor="B1a_4" className="p--sm">
                5
              </label>
              <input
                type="radio"
                id="B1a_5"
                name="B1a"
                value={5}
                {...registerB("B1a", {})}
              />
            </div>
            <div>
              <label htmlFor="B1a_4" className="p--sm">
                6
              </label>
              <input
                type="radio"
                id="B1a_6"
                name="B1a"
                value={6}
                {...registerB("B1a", {})}
              />
            </div>
            <div>
              <label htmlFor="B1a_4" className="p--sm">
                More than 7
              </label>
              <input
                type="radio"
                id="B1a_7"
                name="B1a"
                value={7}
                {...registerB("B1a", {})}
              />
            </div>
          </div>
        </div>
      </div>
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B1a"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionShortText
        question={"B1b"}
        questionText={
          "Have you ever been promoted? What is the highest level of position you have held?"
        }
        register={registerB}
        qErrors={errors.B1b}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B1b"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionShortText
        question={"B1c"}
        questionText={
          "What is the shortest you have ever been in one job and what is the longest you have ever been in one job?"
        }
        register={registerB}
        qErrors={errors.B1c}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B1c"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionShortText
        question={"B1d"}
        questionText={
          "How many years have you been in full-time employment (If you have been absent due to maternity leave or long-term sickness please state how long)? Do not include jobs that you did to support training, or getting a qualification, for example."
        }
        register={registerB}
        qErrors={errors.B1d}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B1d"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionTextBox
        question={"B1e"}
        questionText={
          "If you have stayed at the same job for your entire career, please explain the reason."
        }
        register={registerB}
        qErrors={errors.B1e}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B1e"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionShortText
        question={"B2"}
        questionText={[
          "At any time in your life have you ever had any hobbies or activities that you have pursued for more than 2 years (outside of the regular school /work hours)? This can be any kind of pursuit, e.g. sports, music, clubs, volunteer activities, or hobbies.",
          "If so what and for how long?",
          "Did you progress your ability in these pursuits? Please explain if you took on a leadership or a co-ordinating role in either of these 2 interests?",
        ]}
        register={registerB}
        qErrors={errors.B2}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B2"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionShortText
        question={"B3"}
        questionText={
          "How would you describe yourself in relation to routines at home and work?"
        }
        register={registerB}
        qErrors={errors.B3}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B3"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionMcqYesNoMaybe
        question={"B4a"}
        questionText={
          "Do you think that qualities such as determination, perseverance, and purpose can be learned/developed through conscious effort?"
        }
        register={registerB}
        qErrors={errors.B4a}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B4a"}
          watch={watch}
          scoreType={"Automatically scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionShortText
        question={"B4b"}
        questionText={
          "If you answered Yes to the previous question, how do you think they can be learned?"
        }
        register={registerB}
        qErrors={errors.B4b}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B4b"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionShortText
        question={"B5"}
        questionText={
          "Please describe an example in your life where you have demonstrated resilience and determination. 5-10 sentences."
        }
        register={registerB}
        qErrors={errors.B5}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B5"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionTextBox
        question={"B6"}
        questionText={[
          "Please describe an example in your life where you have demonstrated resilience and determination. 5-10 sentences.",
          "Please give an example that demonstrates some of these characteristics.",
        ]}
        register={registerB}
        qErrors={errors.B6}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B6"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionTextBox
        question={"B7"}
        questionText={"What do you think of competition, in a general sense?"}
        register={registerB}
        qErrors={errors.B7}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B7"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionTextBox
        question={"B8"}
        questionText={
          "What do you think are the key ingredients to becoming successful at something?"
        }
        register={registerB}
        qErrors={errors.B8}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B8"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionShortText
        question={"B9a"}
        questionText={'How would you define a "genius"?'}
        register={registerB}
        qErrors={errors.B9a}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B9a"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionShortText
        question={"B9b"}
        questionText={"How does a person become a genius?"}
        register={registerB}
        qErrors={errors.B9b}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B9b"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionTextBox
        question={"B10"}
        questionText={
          "Please think of a big disappointment in your life. How did you respond to it? (You can choose whether to say what the diasappoint was or not. The main point is to explain how you responded to it.)"
        }
        register={registerB}
        qErrors={errors.B10}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B10"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionTextBox
        question={"B11"}
        questionText={'What does the word "hope" mean to you?'}
        register={registerB}
        qErrors={errors.B11}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B11"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionTextBox
        question={"B12a"}
        questionText={
          "Can you imagine what it would feel like to be hopeless? If you can, how do you imagine it would feel?"
        }
        register={registerB}
        qErrors={errors.B12a}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B12a"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      <QuestionTextBox
        question={"B12b"}
        questionText={
          "If you answered Yes to the previous question - what would you do, or what did you do, if/when you began feeling like that?"
        }
        register={registerB}
        qErrors={errors.B12b}
      />
      {environment === "edit" ? (
        <QuestionAssessmentContainer
          question={"B12b"}
          watch={watch}
          scoreType={"Assessor scored"}
          scoreExplanation={""}
          register={registerB}
        />
      ) : null}
      {formDataBeingSent ? (
        <button type="button" className="cta cta--accent--clicked">
          Submit
        </button>
      ) : null}
      {!formDataBeingSent ? (
        <button type="submit" className="cta cta--accent">
          Submit
        </button>
      ) : null}
    </form>
  );
}

export default withContext(SectionB);
